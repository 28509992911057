import * as React from 'react';
// components
import Layout from '../../components/layout';
import { CalculatorSidebar } from '../../components/calculator-sidebar';
import { Article, HeadTitle } from '@monemprunt/design-system';
import CtaImage from '../../components/cta-image';
import Seo from '../../components/seo';
import { CalculatricePTZ } from '../../components/calculatrice-ptz';
import { Link as LinkComponent } from 'gatsby';

// utils
import { graphql } from 'gatsby';
import { clsx } from '@monemprunt/design-system';

// types & interfaces
import type { PageProps } from 'gatsby';
export interface CalculatricePTZProps {
    SEO: {
        description: string;
        title: string;
    };
    calculatorPage: {
        article: string;
        title: string;
    };
}

export default function CalculatricePTZPage({
    data: {
        page: {
            SEO,
            calculatorPage: { article, title }
        }
    },
    location: { pathname: path }
}: PageProps<{
    page: CalculatricePTZProps;
}>) {
    const [isSidebarOpen, setSidebarOpen] = React.useState(false);

    return (
        <Layout active={`simulation-emprunt-immobilier`}>
            <Seo {...SEO} pathname={path} />
            <main className="mt-25 sm:mt-32 md:mt-20 mb-10">
                <div
                    className="hidden lg:block fixed top-72 h-60 2xl:top-80 2xl:h-80 left-0 right-0"
                    style={{ zIndex: -1 }}>
                    <div className="bg-gradient-to-t from-light-50 to-transparent h-full" />
                    <div className="bg-gradient-to-b from-light-50 to-transparent h-full" />
                </div>

                <div className={'grid grid-cols-10 xl:grid-cols-12'}>
                    {/* SideBar */}
                    <CalculatorSidebar
                        active={'ptz'}
                        isOpen={isSidebarOpen}
                        onToggle={setSidebarOpen}
                    />

                    {/* Content */}
                    <div
                        className={clsx(
                            `col-span-8 px-5 pt-10 flex-col gap-5 mb-32`,
                            'md:pt-15',
                            'lg:px-15 lg:pt-20 lg:col-span-7 lg:flex',
                            'xl:col-span-9',
                            {
                                hidden: isSidebarOpen,
                                flex: !isSidebarOpen
                            }
                        )}>
                        <HeadTitle
                            as="h1"
                            label={title}
                            breakWords={false}
                            className={`lg:text-2xl font-bold uppercase italic`}
                        />

                        {/* Form */}
                        <CalculatricePTZ />

                        {/* Article */}
                        <Article
                            content={article}
                            customLink={(props) => <LinkComponent {...props} />}
                            className={`mt-12`}
                            callToAction={() => <CtaImage />}
                        />
                    </div>
                </div>
            </main>
        </Layout>
    );
}

export const query = graphql`
    query PTZQuery {
        page: strapiPageCalculettePtz {
            SEO {
                description
                title
            }
            calculatorPage {
                article
                title
            }
            strapiId
        }
    }
`;
